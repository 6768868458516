// About.js
import React from 'react';

const AboutMe = () => {
  return (
    <div className="about">
      <img src="/images/IMG_3862-removebg-1920w.png" alt="About Me" />
      <div className="about-text">
        <h2>Lizzie Fox</h2>
        <h3>Creative Director, L'Oreal Colour Specialist</h3>
        <p>Hey I’m Lizzie, I’ve been hairdressing for 20 years, and loved every minute. Throughout my years I’ve built a wonderful loyal clientele and gained a lot of knowledge, I accomplished the L’Oreal Colour Degree which led to me being a colour ID artist, where I taught new collections on stage and had many inspirational days with the best in the hair industry, also travelling to Madrid and Barcelona for expos. Alongside the love of colouring, I assisted at London Fashion week, Central Saint Martins Ma and BA shows, the Clothes Show, a photoshoot in Paris and many more…</p>
        <p>I love all things hair, cutting, styling and colouring, I want everyone to feel their absolute best, encouraging self care whether that is time out of their busy schedule, time dedicated to themselves and boosting self confidence.</p>
        <p>I look forward to welcoming new and current clients to my chair. Making you feel the very best and falling in love with your hair.</p>
        <p>Love</p>
        <p>Lizzie</p>
      </div>
    </div>
  );
};

export default AboutMe;
