// App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';

import './App.css';
import ContactPage from './pages/Contact';
import PricesPage from './pages/Prices';
import Olaplex from './pages/Olaplex';
import Products from './pages/Products';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import About from './pages/About';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='prices' element={<PricesPage />} />
        <Route path='olaplex' element={<Olaplex />} />
        <Route path='products' element={<Products />} />
        <Route path='services' element={<Services />} />
        <Route path='gallery' element={<Gallery />} />
        <Route path='about' element={<About />} />
      </Routes>
    </Router>

  );
};

export default App;
