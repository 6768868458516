// Services.js
import React from 'react';

const Services = () => {
  return (
    <div id='hair-services' className="services">
      {/* Service 1 */}
      <div className="service1">
        <div className='imageButton'>
        <p>Hair</p>
        <a href='/services'><button>Learn More</button></a>
        </div>
      </div>

      {/* Service 2 */}
      <div className="service2">
        <div className='imageButton'>
        <p>Products</p>
        <a href='/products'><button>Learn More</button></a>
        </div>
        
      </div>

      {/* Service 3 */}
      <div className="service3">
        <div className='imageButton'>
        <p>Prices</p>
        <a href='/prices'><button>Learn More</button></a>
        </div>
      </div>
    </div>
  );
};

export default Services;
