// App.js
import React from 'react';
import Nav from '../components/Nav';
import MainHero from '../components/MainHero';
import Services from '../components/Services';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import '../App.css';
import BookButton from '../components/BookButton';

const Home = () => {
  return (
    <div className="app">
      <Nav />
      <BookButton />
      <MainHero HeroTitle={'Lizzie Fox Hair'} />
      <Services />
      <About />
      <Gallery />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;
