import React from 'react';

import Nav from '../components/Nav';
import Products from '../components/Products';
import MainHero from '../components/MainHero';
import Footer from '../components/Footer';
import BookButton from '../components/BookButton';

const ProductPage = () => {
  return (
    <div style={{backgroundColor: "#ab9e90", height: "100%"}}>
        <Nav />
        <BookButton />
        <MainHero
        HeroTitle={"Products"}
        height={"300px"}
        />
        <Products />
        <Footer />
    </div>
  );
};

export default ProductPage;
