// Products.js
import React from 'react';

const Products = () => {
  return (
    <div className="products">
      <div className='mainContainer'>
        <h2>Great Products, Great Results</h2>
        <p>Transform your hair routine with Lizzie Fox Hair's exclusive offerings from L'Oréal Professional! Dive into the rejuvenating power of Metal Detox, designed to purify and revive your locks. Say hello to hair that's free from impurities, ready to shine with newfound vitality! And don't miss out on the magic of Techni Art – a range crafted to unleash your inner stylist. Elevate your hair game with these incredible products because at Lizzie Fox Hair, your hair deserves nothing but the best!</p>
      </div>
      <div className='productContainer'>
        <div className='individualProduct'>
          <img src='/images/lorealProduct.png' alt='LOréal Metal Detox - Detoxifying embrace for refreshed hair' />
          <h3>Hair Care</h3>
          <p>Dive into a world of hair care indulgence with Lizzie Fox Hair's curated selection of L'Oréal Professional products. Immerse yourself in the detoxifying embrace of Metal Detox, banishing impurities for refreshed hair.</p>
        </div>
        <div className='individualProduct'>
          <img src='/images/techniProduct.png' alt='LOréal Techni Art - Sculpt, define, and add the perfect finishing touch for a voluminous look' />
          <h3>Styling</h3>
          <p>Whether it's sculpting, defining, or adding that perfect finishing touch, L'Oreal Techni Art has you covered. Experience the artistry and hair care for a look that speaks volumes. Let your hair be the canvas, and Techni Art be your brush!</p>
        </div>
        <div className='individualProduct' style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/olaplex'}>
          <img src='/images/olaplex.jpg' alt='LOréal Techni Art - Sculpt, define, and add the perfect finishing touch for a voluminous look' />
          <h3>Olaplex</h3>
          <p>Dive into the transformative world of Olaplex, where hair care meets innovation. Olaplex is more than a product; it's a revolution, unlock the secret to amazing hair as Olaplex works its magic, mending bonds and revitalizing from within.</p>
        </div>

      </div>
    </div>
  );
};

export default Products;
