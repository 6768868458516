// Gallery.js
import React from 'react';

const Gallery = () => {
  return (
    <div className="gallery">
      <img src="/images/Facetune_23-11-2022-17-28-56-1920w.jpg" alt="ballyage 1" />
      <img src="/images/IMG_4982-1920w.jpg" alt="ballyage 2" />
      <img src="/images/IMG_5264-1920w.jpg" alt="ballyage 3" />
      <img src="/images/Facetune_30-10-2022-06-36-59-1920w.jpg" alt="ballyage 3" />
      <img src="/images/IMG_5335-1920w.jpg" alt="ballyage 4" />
      <img src="/images/IMG_5048-1920w.jpg" alt="ballyage 5" />
      <img src="/images/IMG_6006-1920w.jpg" alt="ballyage 6" />
      <img src="/images/Screenshot 2022-11-24 at 21.30.57-1920w.png" alt="ballyage 7" />
    </div>
  );
};

export default Gallery;
