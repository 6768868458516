import React from 'react'
import emailjs from "emailjs-com"

export default function ContactForm() {
    
    function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_j1l52o7', 'template_mpc1ja8', e.target, 'A5uq8fgtKxlrfjWP6')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
        e.target.reset()

    }
    // This shows the pop up on form completion
    function myPopUp() {
        document.getElementById('popUp').setAttribute("class", "msgAppear");
        document.getElementById('closePopUp').setAttribute("class", "closeMsg svg")
    }
          
        return (
            <div className="formContainer">
                <form onSubmit={sendEmail} onReset={myPopUp}>
                    {/* Single Item */}
                    <div className="singleItem">
                        <label htmlFor="name">Name</label>
                            <input type="text"
                                name="name"
                                className="name"
                                placeholder="Enter your name"
                            required
                            >
                            </input>
                    </div>
                    {/* End of Single Item */}

                    {/* Single Item */}
                    <div className="singleItem">
                        <label htmlFor="number">Contact Number</label>
                            <input type="text"
                                name="number"
                                className="number"
                                placeholder="Enter Your Number"
                            required
                        >
                            </input>
                    </div>
                    {/* End of Single Item */}

                    {/* Single Item */}
                    <div className="singleItem">
                        <label htmlFor="email">Email</label>
                            <input type="text"
                                name="email"
                                className="email"
                                placeholder="Enter your email address"
                                required
                        >
                            </input>
                    </div>
                    {/* End of Single Item */}

                    {/* Single Item */}
                    <div className="singleItem textArea">
                        <label htmlFor="message">Enter your message below</label>
                        <textarea name="message"
                            id=""
                            cols="30"
                            rows="3"
                            placeholder="Enter your message"
                            required
                        ></textarea>
                    </div>
                    {/* End of Single Item */}
                    <div id="popUp" className="msg">
                        Thanks, your message was sent successfully! 
                    </div>
                    <div id="closePopUp" className="SvgCloseMsg">
                        {/* <RiCloseCircleFill onClick={closePopUp}></RiCloseCircleFill> */}
                        </div>
                    <div class="formBtn">
                        <button type="submit">
                            Send Message
                        </button>
                    </div>
                </form>
            </div>
            
        )
}

