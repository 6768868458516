// ContactPage.js
import React from 'react';
import ContactForm from '../components/ContactForm';
import Nav from '../components/Nav';
import MainHero from '../components/MainHero';
import Footer from '../components/Footer';
import BookButton from '../components/BookButton';

const ContactPage = () => {
  return (
    <div style={{backgroundColor: "#ab9e90"}}>
        <Nav />
        <BookButton />
        <MainHero
        HeroTitle={"Contact"}
        height={"300px"}
        />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactPage;
