
import React from 'react';

const MainHero = ({HeroTitle, height}) => {
  const heroStyle = {
    height: height || '100vh',
  };
  return <div className="main-hero" style={heroStyle}>{HeroTitle}</div>;
};

export default MainHero;
