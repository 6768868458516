// Products.js
import React from 'react';

const HairServices = () => {
  return (
    <div className="products">
      <div className='mainContainer'>
        <h2>Hair Services</h2>
        <p>Welcome to Lizzie Fox Hair, your go-to destination for expert hair services in Derby! Lizzie Fox Hair offers top-notch services tailored to meet your unique needs. Transform your look with our specialized expertise in "Lived In Hair," masterful "Grey Blending,", vibrant "Colour Specialist" treatments and "Cuts & Styles".</p>
        <p>Being proud of my work, I want you to leave the salon looking and feeling fabulous. Discover the artistry of hair care at Lizzie Fox Hair, located in Derby, for exceptional beauty. Book your appointment today and let me bring out the best in your hair journey!</p>
      </div>
      <div className='servicesContainer'>
        <div className='individualProduct'>
          <img src='/images/livedInHair.png' alt='Lived In Hair - Expert coloring techniques for a low-maintenance appearance' />
          <h3>LIVED IN HAIR</h3>
          <p>I employ a variety of expert coloring techniques to seamlessly merge with your natural hair hue, resulting in a chic, low-maintenance appearance. This process enhances your hair with dimension, brightness, and a lustrous shine.</p>
          <p>Expect the stunning results to endure for a remarkable 6 months, with a suggested face-framing touch-up between appointments for optimal maintenance. My approach ensures a gradual and well-blended grow-out phase, contributing to an effortlessly beautiful look.</p>
        </div>
        <div className='individualProduct'>
          <img src='/images/greyBlending.png' alt='Grey Blending - Customized color application to seamlessly camouflage greys' />
          <h3>GREY BLENDING</h3>
          <p>I specialize in delivering a customized color application that not only embraces but also seamlessly camouflages your greys. The result is a beautifully blended, low-maintenance look with a natural effect that truly reflects your style.</p>
          <p>Experience the longevity of this transformation, lasting between 3 to 6 months, and consider a recommended top-up toner in between appointments to keep your look fresh and vibrant.</p>
        </div>
      </div>
      <div className='servicesContainer'>
        <div className='individualProduct'>
          <img src='/images/colourSpecialist.png' alt='Colour Specialist - Diverse array of coloring techniques tailored to individual skin tones' />
          <h3>COLOUR SPECIALIST</h3>
          <p>Drawing on my expertise in coloring, along with a keen eye for assessing individual skin tones, I bring forth a diverse array of coloring techniques tailored to each of my clients.</p>
          <p>Whether you need root coverage, a full head application, semi-permanent color, or highlights, my experience ensures a personalized approach that complements your unique style and features.</p>
        </div>
        <div className='individualProduct'>
          <img src='/images/cutAndStyles.png' alt='Cut & Style - Personalized cuts and styles for individuals with diverse hair lengths, ages, and genders' />
          <h3>CUT & STYLE</h3>
          <p>I take pride in creating personalized cuts and styles for individuals with diverse hair lengths, ages, and genders. Everyone is welcome in my chair, and I'm dedicated to tailoring each experience to bring out the best in your unique features and personal style.</p>
          <p>Whether you're looking for a trendy cut or a classic style, I'm here to make you feel confident and fabulous.</p>
        </div>
      </div>
    </div>
  );
};

export default HairServices;
