import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
  const testimonialsData = [
    {
      review: 'Lizzie has been styling/cutting my hair for well over 10 years. She recently did my hair for my wedding. There’s a reason I don’t go to anyone else, she’s perfect! X',
      name: 'Emma',
    },
    {
      review: 'Lizzie is amazing! Love my hair, such a talented girl! ❤️ Highly recommend! ❤️',
      name: 'Karina',
    },
    {
      review: 'As always, Lizzie has transformed me. Wouldn’t go anywhere else!',
      name: 'Tracey',
    },
    {
      review: 'I have been with Lizzie for about 8 years, won’t go to anyone else! She’s brill!!',
      name: 'Dzi',
    },
    {
      review: 'The best hairdresser I’ve ever had, I always love how she does my hair and adapts it to my hair type and Lizzie always exceeds my expectations.',
      name: 'Carmen',
    },
    {
      review: 'Lizzie is amazing, so happy with the outcome. The colour is phenomenal and I wouldnt hesitate in recommending Lizzie to anyone!',
      name: 'Kirby',
    },

  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2200,
  };

  return (
    <div className="testimonials">
      <h2>Testimonials</h2>
        <div className="stars">★★★★★</div>
      <Slider {...settings}>
        {testimonialsData.map((testimonial, index) => (
          <div key={index}>
            <p>{testimonial.review}</p>
            <p style={{fontWeight:'600'}}>{testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
