import React from 'react';

import Nav from '../components/Nav';
import MainHero from '../components/MainHero';
import Olaplex from '../components/Olaplex';
import Footer from '../components/Footer';
import BookButton from '../components/BookButton';

const PricesPage = () => {
  return (
    <div>
        <Nav />
        <BookButton />
        <MainHero
        HeroTitle={"Olaplex"}
        height={"400px"}
        />
        <Olaplex />
        <Footer />
    </div>
  );
};

export default PricesPage;
