// About.js
import React from 'react';

const About = () => {
  return (
    <div className="about">
      <img src="/images/IMG_3862-removebg-1920w.png" alt="About Me" />
      <div className="about-text">
        <h1>Lizzie</h1>
        <h3>Business Owner & Colour Specialist</h3>
        <p>Hey I’m Lizzie, owner of Lizzie Fox Hair, I’ve been hairdressing for 20 years, and loved every minute. Throughout my years I’ve built a wonderful loyal clientele and gained a lot of knowledge, I accomplished the L’Oreal Colour Degree which led to me being a colour ID artist, where I taught new collections on stage and had many inspirational days with the best in the hair industry, also travelling to Madrid and Barcelona for expos. Alongside the love of colouring, I assisted at London Fashion week, Central Saint Martins Ma and BA shows, the Clothes Show, a photoshoot in Paris and many more… </p>
        <a href='/about'><button>More About Me</button></a>
      </div>
    </div>
  );
};

export default About;
