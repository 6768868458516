// App.js
import React from 'react';
import Nav from '../components/Nav';
import MainHero from '../components/MainHero';
import AboutMe from '../components/AboutMe';
import Footer from '../components/Footer';
import '../App.css';
import BookButton from '../components/BookButton';

const Home = () => {
  return (
    <div className="app">
      <Nav />
      <BookButton />
      <MainHero HeroTitle={'About Me'} height={'300px'} />
      <AboutMe />
      <Footer />
    </div>
  );
};

export default Home;
